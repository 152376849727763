import React from "react"
import styled from 'styled-components'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from '../components/footer'
import Header from '../components/header'
import Section from '../components/sectionContainer'
import { H2 } from '../components/headings'

const IndexPage = () => (
  <Layout>
    <SEO title="Appes - Terms of Service and Privacy Policy" />
    <Header />
    <CoverSection bgColor="#F2F2F2">
      <CoverContainer>
        <CoverLeft />
        <CoverRight>
          <H2>Terms of Service &<br />Privacy Policy</H2>
        </CoverRight>
        <CoverTriangle />
      </CoverContainer>
    </CoverSection>
    <Section>
      <TermsContainer>
        <H2>0. Foreword</H2>
        <hr style={{ color: 'black', height: 1 }}/>
        <Row>
          <Column>
            <p>We hate to deal with huge T&C too, so we are trying to keep this page as brief and friendly as possible. Beware, plain English below!</p>
          </Column>
        </Row>
        <H2>1. Terms</H2>
        <hr style={{ color: 'black', height: 1 }}/>
        <Row>
          <Column>
            <p>Muscă Alexandru-Marius PFA named in this document Appes or, more commonly, we.</p>
            <p>By accessing this website <a aria-label="Link to Appes.co" style={{ color: "#E23C30" }} href="https://appes.co">appes.co</a>, you agree to be bound by these terms of service, all applicable laws and agree that
              you are responsible for compliance with any applicable local laws.</p>
          </Column>
          <Column>
            <p>These terms may change in the future without notice, but you can always review them. By using the website you agree with the current Terms of Service.</p>
            <p>If you do not agree with any of these terms, you can always leave the website.</p>
          </Column>
        </Row>
        <H2>2. Materials</H2>
        <hr style={{ color: 'black', height: 1 }}/>
        <Row>
          <Column>
            <p>You have the permission to download one copy of any materials provided on this website for personal use.</p>
            <p>You can't redistribute the materials to any 3rd parties,
              because downloading the materials doesn't mean a transfer of property. This means you are not allowed to modify these materials, you can't use them for public display or commerial use
              and you can't remove any copyright or branding items.</p>
            <p>Hosting these materials on another server and making them available to other entities is prohibited as well.</p>
          </Column>
          <Column>
            <p>If you violate any of these terms, you will lose ability to use the materials and you must destroy any materias in your possesion.</p>
            <p>The materials are provided on an "as is" basis and Appes makes no warranties. The materials present on the website may include error and Appes doesn't warrant
              that any of the materials is accurate, complete or current. We may make changes to the materials but we are not commited to do so.</p>
          </Column>
        </Row>
        <H2>3. Limitations</H2>
        <hr style={{ color: 'black', height: 1 }}/>
        <Row>
          <Column>
            <p>In no event shall Appes or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption)
              arising out of the use or inability to use the materials on this website, even if Appes or an Appes authorized representative has been notified orally or in writing
              of the possibility of such damage.</p>
          </Column>
        </Row>
        <H2>4. Links</H2>
        <hr style={{ color: 'black', height: 1 }}/>
        <Row>
          <Column>
            <p>Appes has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply
              endorsement by Appes of the site. Use of any such linked website is at the user’s own risk.</p>
          </Column>
        </Row>
        <H2>5. Governing Law</H2>
        <hr style={{ color: 'black', height: 1 }}/>
        <Row>
          <Column>
            <p>These terms are governed by the laws of Focsani, Romania and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
          </Column>
        </Row>
        <H2>6. Privacy Policy</H2>
        <hr style={{ color: 'black', height: 1 }}/>
        <Row>
          <Column>
            <p>Your privacy is important to us, that's why we limited the amount of collected data.</p>
            <p>We collect data ONLY when you are using our contact form. We collect only your name and your email address for the purpose of contacting you back.
              We will retain your data for as long as necessary to contact you.</p>
            <p>If you want to have your data removed, contact us at sayhi@appes.co and you data will be removed in 24 hours.
              If you are visiting our website without using the contact form no data will be collected.</p>
            <p>The website doesn't use any form of tracking cookies. The website visitors will encounter only one technical cookie,
              added by <a aria-label="External link to Cloudflare.com" href="https://www.cloudflare.com/" style={{ color: "#E23C30" }} target="_blank" rel="noopener noreferrer">Cloudflare</a>, our DNS provider.
              The cookie is named __cfduid and it aims to detect malicious visitors and minimizes blocking legitimate users. (We don't own the Cloudflare brand or name.)</p>
            <p>We don't track you, we don't collect data about you. This is not our business model.</p>
          </Column>
          <Column>
            <p>This website uses secure technologies and 3rd party services which comply with the GDPR terms.</p>
            <p>Appes will never share your data with 3rd parties in comercial purposes and will never sell your data.</p>
          </Column>
        </Row>
      </TermsContainer>
    </Section>
    <Footer />
  </Layout>
)

const CoverSection = styled(Section)`
  height: 530px;
  margin-top: 50px;

  @media (max-width: 767px) {
    height: 330px;
  }

  @media (max-width: 1336px) {
    height: 430px;
  }
`

const CoverContainer = styled.div`
  display: flex;
  height: 100%;
  position: relative;
`

const CoverLeft = styled.div`
  flex: 1;

  @media (max-width: 767px) {
    display: none;
  }
`

const CoverRight = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 767px) {
    h2 {
      text-align: center;
    }
  }
`

const CoverTriangle = styled.div`
  width: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  border-style: solid;
  border-width: 0 690px 430px 0;
  border-color:
    transparent transparent #E23C30 transparent;

  @media (max-width: 1336px) {
    border-width: 0 590px 230px 0;
  }

  @media (max-width: 767px) {
    border-width: 0 290px 180px 0;
  }
`

const TermsContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 100px 122px;

  @media (max-width: 1336px) {
    padding: 100px 0;
  }

  @media (max-width: 767px) {
    h2 {
      padding-left: 40px !important;
    }
  }

  p {
    text-align: left;
    font: Regular 22px/26px Noah;
    letter-spacing: -0.55px;
    color: #000000;
  }

  h2 {
    padding-left: 100px;
  }

  h4 {
    text-align: left;
    font: Bold 22px/26px Noah;
    letter-spacing: -0.55px;
    color: #000000;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 90px;
  margin-bottom: 40px;

  @media (max-width: 767px) {
    flex-direction: column;
    padding: 0 30px;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 10px;
`

export default IndexPage
